import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { toAbsoluteUrl } from "../../_helpers";
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Footer } from "../layouts/Footer";
import api from "../../api";
import ReCAPTCHA from "react-google-recaptcha";
import { Notify, clearStorage, clearStorageData, setToken } from "./common-functions";
import { Link } from "react-router-dom";

async function loginUser(credentials) {
    return api.post(`jwt/token`, credentials)
        .then(data => {
            return data.data;
        }).catch(error => {
            Notify("error", error?.response?.data?.detail);
        })
}

export default function LoginPage() {
    const [username, setUserName] = React.useState();
    const [password, setPassword] = React.useState();
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const recaptchaRef = React.createRef();

    const handleSubmit = async e => {
        e.preventDefault();
        recaptchaRef.current.execute();
    }

    const onChange = async (value) => {
        if (!buttonDisabled && value) {
            const token = await loginUser({
                email: username,
                password: password
            })
            setToken(token);
            setButtonDisabled(false);
            clearStorageData();
        }
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>

                    <title>Login</title>
                    <meta name="keywords" content="" />
                    <meta name="description" content="" />
                </Helmet>
            </HelmetProvider>

            <div className='d-flex d-md-flex-row align-items-center gap-2 justify-content-between w-100 w-100 container-fluid1'>
                <div className="d-flex d-md-flex-row align-items-center gap-2 w-100 parent-container">
                    <div className="page-content px-5 py-5 min-h-100 home-content w-100">
                        <div className="login-wrapper">
                            <h1 className="mb-5">Log In</h1>
                            <form onSubmit={handleSubmit} className="auth-form">
                                <div className="mb-2 d-flex flex-col flex-column gap-2">
                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect11" className='w-25'>Email</label>
                                            <Form.Control type="text" name="email" placeholder="Email" className='form-control w-75' onChange={e => setUserName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect11" className='w-25'>Password</label>
                                            <Form.Control type="password" name="password" placeholder="Password" className='form-control w-75' onChange={e => setPassword(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA}
                                    size="invisible"
                                    onChange={onChange}
                                />
                                <div className="mt-3 d-flex gap-2 align-items-center justify-content-between w-100">
                                    <label className='w-25'></label>
                                    <div className="w-75 d-flex gap-2 align-items-center justify-content-end">
                                        <button disabled={buttonDisabled == true} className="btn btn-primary" type="submit">Submit</button>
                                        <Link to={'/reset-password'} className="cursor-pointer mb-0">Reset Password</Link>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

// LoginPage.propTypes = {
//     setToken: PropTypes.func.isRequired
// }
