import React, { useState } from 'react';
import api from '../../../api';
import { getCurrentTimeZone, getLocalStorage, getOptionsLength, getSelectedLength, processSingleSiteFromMedia, setLocalStorage } from '../common-functions';
import CachedIcon from '@mui/icons-material/Cached';
import 'chartjs-plugin-zoom';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import Badge from 'react-bootstrap/Badge';

let params = [];

function customDisposeAllElements() {
    if (am5.registry.rootElements) {
        am5.array.each(am5.registry.rootElements,
            function (rootData) {
                if (rootData.dom.id == 'chartdiv') {
                    rootData.dispose();
                }
            }
        );
    }
}

function generateFilterDataGraphObject(params, isSingle) {
    let getFromCookie = true;
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    var returnObj = {
        'meta.instanceID': $('[name="submission_id"]').val() ? [$('[name="submission_id"]').val()] : '',
        'tz': getCurrentTimeZone()
    };

    if (tempStorageData?.qcFlags?.length && getFromCookie) {
        returnObj['qcFlags'] = tempStorageData?.qcFlags;
    }
    else if (getOptionsLength('[name="filter_qc_flag"]') != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        returnObj['project'] = tempStorageData?.projects;
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        returnObj['formGroup'] = tempStorageData?.formGroups;
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        returnObj['forms'] = tempStorageData?.forms;
    }

    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        if (isSingle) {
            returnObj['sites'] = [tempStorageData?.singleSite?.toString()];
            returnObj['site'] = tempStorageData?.singleSite?.toString();
        } else {
            returnObj['sites'] = [tempStorageData?.singleSite?.toString()];
        }
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        returnObj['clients'] = tempStorageData?.clients;
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        returnObj['monitors'] = tempStorageData?.monitors;
    }

    if (tempStorageData?.params?.length && getFromCookie) {
        returnObj['params'] = tempStorageData?.params;
    }
    else if ($('[name="filter_param"]').val()?.length) {
        returnObj['params'] = $('[name="filter_param"]').val();
    }
    if (!returnObj['params']?.length) {
        const propertyNames = Object.keys(params);
        if (propertyNames?.length) {
            returnObj['params'] = propertyNames;
        }
    }

    returnObj['startDate'] = $('[name="log_start_date"]').val();
    returnObj['endDate'] = $('[name="log_end_date"]').val();
    if ($('[name="keyword"]').val()) {
        returnObj['keyword'] = $('[name="keyword"]').val();
    }
    if (getOptionsLength('[name="filter_editor"]') != getSelectedLength('[name="filter_editor"]')) {
        returnObj['editor'] = $('[name="filter_editor"]').val();
    }
    if (getOptionsLength('[name="filter_image_fields"]') != getSelectedLength('[name="filter_image_fields"]')) {
        returnObj['image_fields'] = $('[name="filter_image_fields"]').val();
    }
    return returnObj;
}

let defaultData = {
    labels: [],
    datasets: [],
};

export const DataGraph = ({ initialFilters, valueDataState, setValueDataState, initialData, allSites }) => {
    const [chartData, setChartData] = React.useState(defaultData);
    const [loadChartData, setLoadChartData] = React.useState(getLocalStorage('isSingleSiteEnabled') || false);
    const [paramsState, setParamsState] = React.useState({});
    const [localStorageDataState, setLocalStorageDataState] = React.useState(getLocalStorage('filter_data_main_table') ?? {});
    if (valueDataState && valueDataState == 5) {
        setTimeout(() => {
        }, 100);
    }

    React.useEffect(async () => {
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        setTimeout(function () {
            select2InitializationGraph();
        }, 1000)
        if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            jQuery('.loader-container').removeClass('d-none');
            loadGraphData();
        } else {
            setLoadChartData(true);
        }
    }, []);

    const loadGraphData = () => {
        am5.disposeAllRootElements();
        setLoadChartData(false);
        setChartData(defaultData);
        setLocalStorageDataState(getLocalStorage('filter_data_main_table') ?? {});
        jQuery('.export-graph-data-main').addClass('d-none');
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            getGraphFilterData().then(res => {
                getGraphData();
            });
        }
    }

    const getGraphFilterData = async () => {
        am5.disposeAllRootElements();
        let postParam = generateFilterDataGraphObject({}, true);
        await api.post(`graph/get-filters-data/`, postParam, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setParamsState(res?.data?.data?.params);
            params = res?.data?.data?.params;
        });
    }

    const getGraphData = async () => {
        const randomColor = [];
        randomColor.push({ primary: 'green', secondary: 'lightgreen' })
        randomColor.push({ primary: 'gray', secondary: 'lightgray' })
        randomColor.push({ primary: 'blue', secondary: 'lightblue' })
        randomColor.push({ primary: 'orange', secondary: 'lightorange' })
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            let postParam = generateFilterDataGraphObject(params, false);
            await api.post(`graph/get-data/`, postParam, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                let tempData = res?.data?.data;
                let data = {};
                data.labels = tempData?.labels;
                data.datasets = tempData?.datasets?.map((rows, index) => {
                    const randomColorTemp = randomColor[Math.floor((Math.random() * 3))];
                    const temp1 = randomColorTemp.secondary;
                    const temp = randomColorTemp.primary;
                    return {
                        ...rows,
                        borderColor: temp,
                        backgroundColor: temp1,
                    };
                });
                setChartData(data);
                if (data.datasets && data.datasets?.length) {
                    setLoadChartData(true);
                    jQuery('.export-graph-data-main').removeClass('d-none');
                    jQuery('.filter-media-graph-data-main').removeClass('d-none');
                    jQuery('.refresh-media-graph-data-main').removeClass('d-none');
                    jQuery('.reset-media-graph-data-main').removeClass('d-none');
                    setTimeout(() => {
                        am5.disposeAllRootElements();
                        let root = am5.Root.new("chartdiv");
                        root.setThemes([
                            am5themes_Animated.new(root)
                        ]);
                        let chart = root.container.children.push(am5xy.XYChart.new(root, {
                            panX: true,
                            panY: true,
                            wheelX: "panX",
                            wheelY: "zoomX",
                            layout: root.verticalLayout,
                            maxTooltipDistance: 0,
                            pinchZoomX: true
                        }));
                        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                            maxDeviation: 0.2,
                            baseInterval: {
                                timeUnit: "day",
                                count: 1
                            },
                            renderer: am5xy.AxisRendererX.new(root, {}),
                            tooltip: am5.Tooltip.new(root, {})
                        }));
                        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                            renderer: am5xy.AxisRendererY.new(root, {})
                        }));

                        chart.get("colors").set("colors", [
                            am5.color(0x095256),
                            am5.color(0x087f8c),
                            am5.color(0x5aaa95),
                            am5.color(0x86a873),
                            am5.color(0xbb9f06),
                            am5.color(0xdc3545),
                            am5.color(0x0d6efd),
                            am5.color(0x007bff),
                            am5.color(0x212529),
                            am5.color(0x495057),
                            am5.color(0x6c757d),
                            am5.color(0x198754),
                            am5.color(0xffc107),
                            am5.color(0xff5807),
                            am5.color(0x197687),
                        ]);

                        tempData?.datasets?.map((rowsData, index) => {
                            if (params[rowsData.label] && (params[rowsData.label] == 2 || params[rowsData.label] == 3)) {
                                let series = chart.series.push(am5xy.LineSeries.new(root, {
                                    name: rowsData.label,
                                    xAxis: xAxis,
                                    yAxis: yAxis,
                                    valueYField: "value",
                                    valueXField: "date",
                                    legendValueText: "{valueY}"
                                }));
                                series.hide();
                                if (params[rowsData.label] && params[rowsData.label] == 3) {
                                    series.hide();
                                } else if (params[rowsData.label] && params[rowsData.label] == 2) {
                                    series.appear();
                                }
                                setTimeout(() => {
                                    series.strokes.template.setAll({
                                        strokeWidth: 3,
                                        templateField: "strokeSettings",
                                    });
                                    let tempDataArray = [];
                                    let bullets = [];
                                    rowsData.data?.map((rows, index1) => {
                                        const dateData = new Date(data.labels[index1]);
                                        const hour = dateData.getHours();
                                        const min = dateData.getMinutes();
                                        const seconds = dateData.getSeconds();
                                        const timeData = "(" + hour + ":" + min + ":" + seconds + ")";
                                        var unixTime = dateData.getTime();
                                        var labelToBeShown = rows + " " + timeData;

                                        bullets.push(rows);
                                        tempDataArray.push({
                                            date: unixTime,
                                            value: rows,
                                            label: labelToBeShown,
                                            bullets: true,
                                            strokeSettings: {
                                                strokeWidth: 3,
                                                templateField: "strokeSettings",
                                            }
                                        });
                                    });
                                    series.bullets.push(function () {
                                        var circleBullet = am5.Bullet.new(root, {
                                            sprite: am5.Circle.new(root, {
                                                radius: 5,
                                                fill: series.get("fill")
                                            })
                                        });
                                        circleBullet.get("sprite").set("tooltipText", "[weight: 600 color: #fff fontSize: 16px]{valueY}[/] [fontSize: 12px verticalAlign: center]🕒[/] [fontSize: 12px verticalAlign: center]{valueX.formatDate('HH:mm')}{valueX.getSeconds() !== 0 ? ':' + valueX.formatDate('ss') : ''}[/]");
                                        return circleBullet;
                                    });
                                    series.data.setAll(tempDataArray);
                                }, 500);
                            }
                        })
                        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                            behavior: "none"
                        }));
                        cursor.lineY.set("visible", false);
                        chart.set("scrollbarX", am5.Scrollbar.new(root, {
                            orientation: "horizontal"
                        }));
                        chart.set("scrollbarY", am5.Scrollbar.new(root, {
                            orientation: "vertical"
                        }));
                        xAxis.set("tooltip", am5.Tooltip.new(root, {
                            themeTags: ["axis"]
                        }));
                        yAxis.set("tooltip", am5.Tooltip.new(root, {
                            themeTags: ["axis"],
                            labelText: "{valueY} ({valueX.formatDate('HH:mm')}{valueX.getSeconds() !== 0 ? ':' + valueX.formatDate('ss') : ''})"
                        }));
                        let legend = chart.topAxesContainer.children.push(am5.Legend.new(root, {
                            y: am5.percent(0),
                            centerX: am5.percent(0),
                            x: am5.percent(0),
                            useDefaultMarker: true,
                            layout: am5.GridLayout.new(root, {
                                maxColumns: 4,
                                fixedWidthGrid: true
                            }),
                        }));


                        legend.markerRectangles.template.setAll({
                            cornerRadiusTL: 10,
                            cornerRadiusTR: 10,
                            cornerRadiusBL: 10,
                            cornerRadiusBR: 10
                        });

                        legend.markers.template.setAll({
                            width: 20,
                            height: 20
                        });

                        legend.itemContainers.template.events.on("pointerover", function (e) {
                            let itemContainer = e.target;
                            let series = itemContainer.dataItem.dataContext;
                            chart.series.each(function (chartSeries) {
                                if (chartSeries != series) {
                                    chartSeries.strokes.template.setAll({
                                        strokeOpacity: 0.15,
                                        stroke: am5.color(0x000000)
                                    });
                                } else {
                                    chartSeries.strokes.template.setAll({
                                        strokeWidth: 3
                                    });
                                }
                            })
                        })
                        legend.itemContainers.template.events.on("pointerout", function (e) {
                            let itemContainer = e.target;
                            let series = itemContainer.dataItem.dataContext;
                            chart.series.each(function (chartSeries) {
                                chartSeries.strokes.template.setAll({
                                    strokeOpacity: 1,
                                    strokeWidth: 1,
                                    stroke: chartSeries.get("fill")
                                });
                            });
                        })
                        legend.valueLabels.template.setAll({
                            textAlign: "right"
                        });
                        legend.data.setAll(chart.series.values);
                        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                        am5plugins_exporting.Exporting.new(root, {
                            menu: am5plugins_exporting.ExportingMenu.new(root, {
                                align: "right",
                                valign: "top",
                                container: document.getElementById("exportdiv1")
                            }),
                            filePrefix: new Date().getTime() + ' - ' + tempStorageData?.singleSite + ' - ' + tempStorageData?.singleSiteName + ' - Graph Data'
                        });
                        chart.appear(1000, 100);
                        $('#exportdiv').off('click');
                        $('#exportdiv').on('click', function () {
                            $('.am5exporting-menu').toggleClass('am5exporting-menu-open')
                        })
                        jQuery('.loader-container').addClass('d-none');
                        $('.middle').addClass('d-none');
                    }, 100);
                } else {
                    setLocalStorageDataState(getLocalStorage('filter_data_main_table') ?? {});
                    setLoadChartData(false);
                    $('.middle').removeClass('d-none');
                }

            }).catch(error => {
                jQuery('.loader-container').addClass('d-none');
            })
        } else {
            setTimeout(() => {
                jQuery('.loader-container').addClass('d-none');
            }, 1000);
        }
    };

    const processSingleSiteFromMediaLocal = (eventData) => {
        am5.disposeAllRootElements();
        jQuery('.loader-container').removeClass('d-none');
        processSingleSiteFromMedia(eventData, 'graph');
        setTimeout(() => {
            setLocalStorageDataState(getLocalStorage('filter_data_main_table') ?? {});
        }, 1000);
    }

    const select2InitializationGraph = () => {
        var $_this = jQuery('.select2-init-graph');
        $_this.hide();
        $_this.addClass('d-none');
        if (jQuery('.multiselect-native-select').length > 0) {
            $_this.multiselect('destroy');
        }
        $_this.multiselect({
            numberDisplayed: 1,
            includeSelectAllOption: true,
            enableFiltering: true,
            filterPlaceholder: 'Search for something...',
            enableCaseInsensitiveFiltering: true,
            maxHeight: 310,
            dropUp: false,
            onDropdownHide: function (event) {
            },
            onChange: function (element, checked) {
                processSingleSiteFromMediaLocal(element[0])
            },
        });

        setTimeout(() => {
            let isSingleSiteEnabled = getLocalStorage('isSingleSiteEnabled') || false;
            const tempUrl = new URL(location);
            var currentSiteFromUrl = tempUrl.searchParams.get("site");
            var currentSiteFromUrlToId = allSites?.find(rowsSite => rowsSite?.label == currentSiteFromUrl)?.value;
            let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
            if (tempStorageData.singleSite && currentSiteFromUrl != '' && currentSiteFromUrl != null && tempStorageData.singleSite != '' && tempStorageData.singleSite != currentSiteFromUrlToId) {
                isSingleSiteEnabled = false;
            }
            if (!isSingleSiteEnabled) {
                if ((!currentSiteFromUrl && !currentSiteFromUrlToId) && tempStorageData?.sites && tempStorageData?.sites?.length && tempStorageData?.sites?.length == 1) {
                    currentSiteFromUrlToId = tempStorageData?.sites[0];
                    currentSiteFromUrl = allSites?.find(rows => rows.value == currentSiteFromUrlToId)?.label;
                }
                if (currentSiteFromUrl || currentSiteFromUrlToId) {
                    var el = $("<input/>", { 'data-site': currentSiteFromUrlToId, 'data-site-name': currentSiteFromUrl, 'value': currentSiteFromUrlToId + '|||' + currentSiteFromUrl });
                    processSingleSiteFromMediaLocal(el);
                    setLocalStorage(true, 'isSingleSiteEnabled');
                }

                let url = new URL(location.href);
                url.searchParams.delete('site');
                window.history.pushState('', '', url);
            }
        }, 1000);
    }

    return (
        <>
            <div className='p-0 h-100'>
                <div className="min-h-53vh h-100 w-100 position-relative">
                    <button type="button" className="d-none refresh-graph-data float-right btn btn-primary shadow-none mr-2 fa fa-reload" title="Get Latest Data" onClick={() => loadGraphData()}>
                        <CachedIcon />
                    </button>

                    <button type="button" className="reload-graph-initialization float-right btn btn-primary shadow-none mr-2 fa fa-reload d-none" onClick={() => select2InitializationGraph()}>
                        <CachedIcon />
                    </button>
                    <div className="w-100 bg-white">
                        <div id="chartdiv" className={loadChartData == true && chartData?.datasets?.length ? "table-comment-custom-height" : "table-comment-custom-height d-none"} style={{ width: "100%", height: 'calc(100vh - 210px)' }}></div>
                        {
                            localStorageDataState?.singleSiteMode == true && localStorageDataState?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1 &&
                                loadChartData == true && chartData?.datasets?.length ?
                                <>

                                </>
                                :
                                <>
                                    {localStorageDataState?.singleSiteMode == true && localStorageDataState?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1 && localStorageDataState > 0 ?
                                        <div className="middle">
                                            <h1>This site does not have parameter data to chart.</h1>
                                        </div>
                                        :
                                        <>
                                            {!chartData?.datasets?.length &&
                                                !localStorageDataState?.singleSiteMode && process.env.REACT_APP_SINGLE_SITE_MODE == 1 ?
                                                <div className="middle">
                                                    <h1>Select Site</h1>
                                                    <div className='w-100'>
                                                        <div className="form-group d-flex flex-row align-items-center gap-2 filter-site-media">
                                                            <select name='filter_site_media' className="w-100 select2-init-graph form-control"
                                                                onChange={() => processSingleSiteFromMediaLocal(event)}>
                                                                <option data-site='' data-site-name='' value='' key='site-first'>Please select site</option>
                                                                {allSites.map(({ value, label }, index) => <option
                                                                    data-site={value} data-site-name={label}
                                                                    value={value + '|||' + label} key={'site-' + index}>{label}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                                : <>
                                                    <div className="middle d-none">
                                                        <h1>This site does not have parameter data to chart.</h1>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}