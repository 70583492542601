import { createSlice } from "@reduxjs/toolkit";
// import CommonService from "../../app/services/CommonService";

const initialState = {
    error: null,
    loading: false,
};

export const AuthSlices = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        endCall: (state, action) => {
            state.error = null;
            state.loading = false;
        },

        // catchError: (state, action) => {
        //     state.error = action.payload.message;
        //     state.loading = false;
        //     CommonService.exceptionPrompt('Error!', action.payload.message, 'error');
        // },

    }
});