import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { Link } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import { getLocalUser, logout, getLocalStorage, disableSingleSite } from "../pages/common-functions.js"

export const Header = () => {
  let user = getLocalUser();
  const [tempStorageData, setTempStorageData] = useState({});
  useEffect(() => {
    const interval = setInterval(() => {
      setTempStorageData(getLocalStorage('filter_data_main_table') ?? {});
    }, 1000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="d-flex flex-row justify-between clearfix justify-content-around align-items-center">
        <div className="header-nav navbar-light p-4 w-100 max-h-5 d-flex">

          <div className="float-left flex-grow-1 align-items-center d-flex">
            <Link to="/" className="d-inline-block">
              {
                process.env.REACT_APP_IS_LOCAL == 0 ?
                  <img className="img-responsive img-fluid madera-logo" src={toAbsoluteUrl('static/media/madera-logo.png')} alt="DE" />
                  :
                  <img className="img-responsive img-fluid madera-logo" src={toAbsoluteUrl('media/madera-logo.png')} alt="DE" />
              }
            </Link>
          </div>

          <nav className="header-nav navbar navbar-expand-lg ml-auto float-md-left">


            <button className="ml-md-auto navbar-toggler float-md-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse pr-4" id="navbarSupportedContent">

              <div className="d-flex justify-content-between align-items-center mb-4 d-lg-none">
                <div className="ml-md-auto d-flex flex-row align-items-center gap-3 ml-auto right-profile-icon float-right">
                  {/* {(user && user.name) && <Avatar className=" float-right user-avatar" />} */}
                  {(user && user.name) && <Typography component="h4" className={''}>
                    Hi {user?.name}
                  </Typography>
                  }
                </div>

                <svg data-toggle="collapse" data-target="#navbarSupportedContent" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width='24' height='24'>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>

              {(user && user.user_type === 1) ?
                <ul className="navbar-nav ml-auto text-left justify-content-end d-lg-flex lg-flex-row w-100 nav-ul">

                  {(tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) &&
                    <li className="nav-item">
                      <a href="#" className="nav-link mb-0" aria-current="page">Single site: {tempStorageData?.singleSiteName}</a>
                      {/* <a href="#" onClick={() => disableSingleSite()} className="nav-link mb-0 d-none" aria-current="page">Clear single site selection: {tempStorageData?.singleSiteName}</a> */}
                    </li>
                  }
                  <li className="nav-item">
                    <a href={process.env.REACT_APP_META_URL} target={'_blank'} className="nav-link mb-0" aria-current="page">Metadata</a>
                  </li>
                  <li className="nav-item d-lg-none d-md-block">
                    <Link className={'nav-link mb-0'} to={''} onClick={logout}>
                      Logout
                    </Link>
                  </li>
                </ul>
                :
                <ul className="navbar-nav ml-auto text-left justify-content-end d-lg-flex lg-flex-row w-100 nav-ul">
                  {(tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) &&
                    <li className="nav-item">
                      <a href="#" className="nav-link mb-0" aria-current="page">Single site: {tempStorageData?.singleSiteName}</a>
                      {/* <a href="#" onClick={() => disableSingleSite()} className="nav-link mb-0 d-none" aria-current="page">Clear single site selection: {tempStorageData?.singleSiteName}</a> */}
                    </li>
                  }

                  <li className="nav-item d-lg-none d-md-block">
                    <Link className={'nav-link mb-0'} to={''} onClick={logout}>
                      Logout
                    </Link>
                  </li>
                </ul>
              }
            </div>
          </nav>

          <div className="ml-md-auto d-flex flex-row align-items-center gap-4 ml-auto right-profile-icon float-right d-none d-lg-flex">

            {(user && user.name) && <Typography component="h4" className={'text-white'}>
              Hi {user?.name}
            </Typography>
            }

            {user?.name ?
              <Typography component="h4" className={'text-white'}>
                <Link className={'text-white text-decoration-none'} to={''} onClick={logout}>
                  Logout
                </Link>
              </Typography>
              : ''}

            {/* {(user && user.name) && <Avatar className=" float-right user-avatar" />} */}

          </div>
        </div>
      </div>
    </>
  );
};
