import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { Link } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import { getLocalUser, logout, getLocalStorage, disableSingleSite } from "../pages/common-functions.js"

export const NonAuthHeader = () => {
  let user = getLocalUser();
  const [tempStorageData, setTempStorageData] = useState({});
  useEffect(() => {
    const interval = setInterval(() => {
      setTempStorageData(getLocalStorage('filter_data_main_table') ?? {});
    }, 1000);
  }, []);

  return (
    <>
      <div className="d-flex flex-row justify-between clearfix justify-content-around align-items-center">
        <div className="header-nav navbar-light p-4 w-100 max-h-5 d-flex">
          <div className="float-left flex-grow-1 align-items-center d-flex">
            {
              process.env.REACT_APP_IS_LOCAL == 0 ?
                <img className="img-responsive img-fluid madera-logo" src={toAbsoluteUrl('static/media/madera-logo.png')} alt="DE" />
                :
                <img className="img-responsive img-fluid madera-logo" src={toAbsoluteUrl('media/madera-logo.png')} alt="DE" />
            }
          </div>
        </div>
      </div>
    </>
  );
};
