import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query';

import { ErrorPage } from "./pages/ErrorPage";
import { Data } from "./pages/Data";
import { Header } from "./layouts/Header";
import { NonAuthHeader } from "./layouts/NonAuthHeader";
import { Footer } from "./layouts/Footer";
import ResetPassword from "./pages/ResetPassword";
import LoginPage from "./pages/Login";
import { getLocalUser, getToken } from "./pages/common-functions";
import SetPassword from "./pages/SetPassword";

export default function BasePage() {
  const token = getToken();
  const user = getLocalUser();
  const queryClient = new QueryClient({
    defautlOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        retry: 0
      }
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      <div className="container-fluid1">
        <div className="page-bg">
          {!token ?
            <NonAuthHeader />
            :
            <Header />
          }
          <div className="parent-container">
            <Switch className='p-2'>
              {!token ?
                <Route exact path="/" component={LoginPage} />
                :
                <Route exact path="/" component={Data} />
              }
              <Route path="/data" exact={true} component={Data} />
              <Route path="/reset-password" exact={true} component={ResetPassword} />
              <Route path="/password_reset/confirm" exact={true} component={SetPassword} />
              <Route path="/login" exact={true} component={LoginPage} />
              <Route path="/error" exact={true} component={ErrorPage} />
              <Redirect to="/error" />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </QueryClientProvider>
  );
}
