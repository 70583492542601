import {combineReducers} from "redux";
import {persistReducer} from 'redux-persist'

import {CommonSlices} from "./common/slices";
import {AuthSlices} from "./auth/slices";
import storage from 'redux-persist/lib/storage'

const authPersistConfig = {
    key: 'auth',
    storage: storage
}

export const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, AuthSlices.reducer),
    common: CommonSlices.reducer,
});