import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export function ErrorPage() {
    return (
        <>
            <HelmetProvider>
                <Helmet>

                    <title></title>
                    <meta name="keywords" content="" />
                    <meta name="description" content="" />
                </Helmet>
            </HelmetProvider>

            <div className="page-content px-5 py-5 error-page">
                <p>Error Page</p>
            </div>
        </>
    );
}
