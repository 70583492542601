import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';

export const ExcelTable = (props) => {
    return (
        <div style={{display: 'block'}}>
            <HotTable
            data={props.data}
            columns={props.headers}
            colHeaders={props.colHeaders}
            rowHeaders={true}
            height="auto"
            afterChange={props.handleChange}
            licenseKey="non-commercial-and-evaluation"
            />
        </div>
    );
};
