import { CenteredModal } from './CenteredModal'
import {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {useMutation} from 'react-query';
import {updateRepeatedData} from '../../queries'
import { Notify } from './common-functions.js'
import 'handsontable/dist/handsontable.full.min.css';

import { ExcelTable } from './Handsontable';
var changeLog={};
export const RepeatTableModal = (props) => {
    const [loadedTable, setLoadedTable] = useState(false)
    const [data, setData] = useState(props.data)
    const [headers, setHeaders] = useState(props.headers)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const {mutate: updateRepeatData} = useMutation(['updateRepeatedData', props.meta_id, props.field], () => updateRepeatedData(props.meta_id, props.field, changeLog), {
        onSuccess:(response) => {
            setButtonDisabled(false)
            Notify("info", "Successfully Edited");
            jQuery('.loader-container').addClass('d-none');
            props.onHide()
        }
      })

    useEffect(() => {
        window.setTimeout(function () {
            setLoadedTable(true)
            },1000);
    }, []);

    const handleChange = (changes, source) => {
        // Handle cell changes here
        if (source === 'edit') {
            const newData = [...data];
            changes.forEach(change => {
            const [row, prop, oldValue, newValue] = change;
            newData[row][prop] = newValue;
            const columnIndex = '__id'//props.headers.map(header => header.data).indexOf('__id');
            let col = prop//props.headers[prop]
            if (changeLog.hasOwnProperty(row)) {
                changeLog[newData[row][columnIndex]][col]=newValue
            } else {
                changeLog[newData[row][columnIndex]]={}
                changeLog[newData[row][columnIndex]][col]=newValue
            }
            });
            setData(newData);
        }
        };

    const updateData = () => {
        setButtonDisabled(true)
        jQuery('.loader-container').removeClass('d-none');
        jQuery('.no-record-container').addClass('d-none');
        updateRepeatData()
    }

    const table =  loadedTable ? <ExcelTable
                        data={data}
                        headers={headers}
                        colHeaders={props.colHeaders}
                        handleChange={handleChange}
                    /> : null

    return (
        <CenteredModal
            title={props.meta_id}
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                {table}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => updateData()} variant="secondary" disabled={buttonDisabled}>Save</Button>
                <Button onClick={props.onHide} variant="danger">Close</Button>
            </Modal.Footer>
        </CenteredModal>
    );
}
