import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { toAbsoluteUrl } from "../../_helpers";
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Footer } from "../layouts/Footer";
import api from "../../api";
import ReCAPTCHA from "react-google-recaptcha";
import { Notify, clearStorage, clearStorageData, getUrlVars, setToken } from "./common-functions";
import { Link } from "react-router-dom";
import { CirclesWithBar } from "react-loader-spinner";

async function setUserPassword(credentials) {
    jQuery('.loader-container').removeClass('d-none');
    return api.post(`password_reset/confirm/`, credentials)
        .then(data => {
            jQuery('.loader-container').addClass('d-none');
            Notify("success", 'Password updated successfully.');
            setTimeout(() => {
                window.location.href = '/login' + location.search;
            }, 1000);
        }).catch(error => {
            jQuery('.loader-container').addClass('d-none');
            if (error?.response?.data?.detail) {
                Notify("error", error?.response?.data?.detail);
            }
        })
}

export default function SetPassword() {
    const [password, setPassword] = React.useState();
    const [userToken, setUserToken] = React.useState(getUrlVars()['token'] ?? '');
    const [confirmPassword, setConfirmPassword] = React.useState();
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const recaptchaRef = React.createRef();

    const handleSubmit = async e => {
        e.preventDefault();
        recaptchaRef.current.execute();
    }

    const onChange = async (value) => {
        if (!buttonDisabled && value && password == confirmPassword) {
            await setUserPassword({
                password: password,
                token: userToken,
            }).then(res => {

            })
            setButtonDisabled(false);
        }
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Set New Password</title>
                    <meta name="keywords" content="" />
                    <meta name="description" content="" />
                </Helmet>
            </HelmetProvider>

            <div className='d-flex d-md-flex-row align-items-center gap-2 justify-content-between w-100 w-100 container-fluid1'>
                <CirclesWithBar
                    height="100"
                    width="100"
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass="flex items-center w-full loader-container d-none"
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                />
                <div className="d-flex d-md-flex-row align-items-center gap-2 w-100 parent-container">
                    <div className="page-content px-5 py-5 min-h-100 home-content w-100">
                        <div className="login-wrapper">
                            <h1 className="mb-5">Set Your New Password</h1>
                            <form onSubmit={handleSubmit} className="auth-form">
                                <div className="mb-2 d-flex flex-col flex-column gap-2">
                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2 d-none">
                                            <label className='w-25'>Token</label>
                                            <Form.Control autoComplete="off" type="text" name="token" placeholder="Token" className='form-control w-75 readonly' value={userToken} onChange={e => setUserToken(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect11" className='w-25'>Password</label>
                                            <Form.Control autoComplete="off" type="password" name="password" placeholder="Password" className='form-control w-75' onChange={e => setPassword(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect11" className='w-25'>Confirm Password</label>
                                            <Form.Control type="password" name="confirm_password" placeholder="Confirm Password" className='form-control w-75' onChange={e => setConfirmPassword(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA}
                                    size="invisible"
                                    onChange={onChange}
                                />
                                <div className="mt-3 d-flex gap-2 align-items-center justify-content-between w-100">
                                    <label className='w-25'></label>
                                    <div className="w-75 d-flex gap-2 align-items-center justify-content-end">
                                        <button disabled={buttonDisabled == true} className="btn btn-primary" type="submit">Submit</button>
                                        <Link to={'/login'} className="cursor-pointer mb-0">Back to login</Link>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}