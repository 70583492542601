import API from './api';

export async function repeatedData (meta_id, field) {
    let response = await API.get(`/repeated_data/${meta_id}/${field}/`)
    return response.data;
}

export async function updateRepeatedData (meta_id, field, params) {
    let response = await API.put(`/update_repeated_data/${meta_id}/${field}/`, params)
    return response.data;
}
