import * as React from 'react';
import API from '../../../api';
import { clearCache, getCurrentTimeZone, getLocalStorage, getOptionsLength, getSelectedLength, processSingleSiteFromMedia, setLocalStorage } from '../common-functions';
import CachedIcon from '@mui/icons-material/Cached';
import '../../pages/style.css'
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/scss/bootstrap.scss';
import "handsontable/dist/handsontable.min.css";
import "pikaday/css/pikaday.css";
import 'handsontable/dist/handsontable.full.css';
import Badge from 'react-bootstrap/Badge';
jQuery.fn.modal.Constructor.prototype.enforceFocus = function () { };
jQuery.fn.modal.Constructor.prototype._enforceFocus = function () { };
jQuery.fn.modal.Constructor.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };


function generateFilterDataObject() {
    let getFromCookie = true;
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    var returnObj = {
        'meta.instanceID': $('[name="submission_id"]').val() ? [$('[name="submission_id"]').val()] : '',
        'tz': getCurrentTimeZone()
    };

    if (tempStorageData?.qcFlags?.length && getFromCookie) {
        returnObj['qcFlags'] = tempStorageData?.qcFlags;
    }
    else if (getOptionsLength('[name="filter_qc_flag"]') != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        returnObj['project'] = tempStorageData?.projects;
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        returnObj['formGroup'] = tempStorageData?.formGroups;
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        returnObj['forms'] = tempStorageData?.forms;
    }

    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        returnObj['site'] = tempStorageData?.singleSite?.toString();
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        returnObj['clients'] = tempStorageData?.clients;
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        returnObj['monitors'] = tempStorageData?.monitors;
    }

    if (getOptionsLength('[name="filter_param"]') != getSelectedLength('[name="filter_param"]') && $('[name="filter_param"]').val()?.length) {
        returnObj['params'] = $('[name="filter_param"]').val();
    }

    returnObj['startDate'] = $('[name="log_start_date"]').val();
    returnObj['endDate'] = $('[name="log_end_date"]').val();
    if ($('[name="keyword"]').val()) {
        returnObj['keyword'] = $('[name="keyword"]').val();
    }
    if (getOptionsLength('[name="filter_editor"]') != getSelectedLength('[name="filter_editor"]')) {
        returnObj['editor'] = $('[name="filter_editor"]').val();
    }
    if (tempStorageData?.image_fields?.length && getFromCookie) {
        returnObj['image_fields'] = tempStorageData?.image_fields;
    }
    else if ($('[name="filter_image_fields"]').val()?.length && getOptionsLength('[name="filter_image_fields"]') != getSelectedLength('[name="filter_image_fields"]')) {
        returnObj['image_fields'] = $('[name="filter_image_fields"]').val();
    }
    return returnObj;
}

export const DataMedia = ({ initialFilters, valueDataState, setValueDataState, initialDataFromParent, allSites }) => {
    const [busy, setBusy] = React.useState([]);
    const [loadMediaData, setLoadMediaData] = React.useState(getLocalStorage('isSingleSiteEnabled') || false);
    const [initialData, setInitialData] = React.useState([]);
    const [localStorageDataState, setLocalStorageDataState] = React.useState(getLocalStorage('filter_data_main_table') ?? {});
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    // Get data from API
    const getMediaData = async (isInitial, closeModal = true) => {
        jQuery('.loader-container').removeClass('d-none');
        jQuery('.no-record-comments-container').addClass('d-none');
        getDataPaginatedForMedia();
    };

    // Get data from API
    const getDataPaginatedForMedia = async () => {
        setLoadMediaData(false);
        jQuery('.loader-container').removeClass('d-none');
        jQuery('.no-record-comments-container').addClass('d-none');
        let postParam = generateFilterDataObject();
        if (postParam['site']) {
            await API.post(`media/get-data/`, postParam, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                setBusy(false);
                let resData = res?.data?.data?.images;
                if (resData) {
                    let responseData = resData;
                    let propertyNames = Object.keys(responseData);
                    let resDataArray = [];
                    let resDataArrayTemp = [];
                    propertyNames.map(rows => {
                        resDataArray.push({ label: rows, value: responseData[rows] })
                        resDataArrayTemp.push(rows)
                    })
                    setInitialData(resDataArray);
                    setIsDataLoaded(true);
                    setLoadMediaData(true);
                    jQuery('.filter-media-graph-data-main').removeClass('d-none');
                    jQuery('.refresh-media-graph-data-main').removeClass('d-none');
                    jQuery('.reset-media-graph-data-main').removeClass('d-none');
                    if (responseData?.length > 0) {
                        $('.no-media-data-container').addClass('d-none')
                    } else {
                        $('.no-media-data-container').removeClass('d-none')
                    }
                }
                setTimeout(() => {
                    jQuery('.loader-container').addClass('d-none');
                }, 1000);
            }).catch(error => {
                setBusy(false);
                setInitialData([]);
                jQuery('.loader-container').addClass('d-none');
                jQuery('.no-record-comments-container').removeClass('d-none');
                setTimeout(() => {
                    clearCache(error);
                }, 200);
            });
        } else {
            $('.media-select-site-data-container').removeClass('d-none')
            setTimeout(() => {
                jQuery('.loader-container').addClass('d-none');
            }, 1000);
        }
    }

    // Inital load
    React.useEffect(async () => {
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        setTimeout(function () {
            select2InitializationMedia();
        }, 1000)
        if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            jQuery('.loader-container').removeClass('d-none');
            getMediaData(true).then(res1 => {
                setBusy(false);
            });
        } else {
            setLoadMediaData(true);
        }
    }, []);

    const processSingleSiteFromMediaLocal = (eventData) => {
        processSingleSiteFromMedia(eventData, 'media');
        setTimeout(() => {
            setLocalStorageDataState(getLocalStorage('filter_data_main_table') ?? {});
        }, 1000);
    }

    const select2InitializationMedia = () => {
        var $_this = jQuery('.select2-init-media-site');
        $_this.hide();
        $_this.addClass('d-none');
        if (jQuery('.multiselect-native-select').length > 0) {
            $_this.multiselect('destroy');
        }
        $_this.multiselect({
            numberDisplayed: 1,
            includeSelectAllOption: true,
            enableFiltering: true,
            filterPlaceholder: 'Search for something...',
            enableCaseInsensitiveFiltering: true,
            maxHeight: 310,
            dropUp: false,
            onDropdownHide: function (event) {
            },
            onChange: function (element, checked) {
                processSingleSiteFromMediaLocal(element[0])
            },
        });

        setTimeout(() => {
            let isSingleSiteEnabled = getLocalStorage('isSingleSiteEnabled') || false;
            const tempUrl = new URL(location);
            var currentSiteFromUrl = tempUrl.searchParams.get("site");
            var currentSiteFromUrlToId = allSites?.find(rowsSite => rowsSite?.label.indexOf(currentSiteFromUrl) >= 0)?.value;
            let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
            if (tempStorageData.singleSite && currentSiteFromUrl != '' && currentSiteFromUrl != null && tempStorageData.singleSite != '' && tempStorageData.singleSite != currentSiteFromUrlToId) {
                isSingleSiteEnabled = false;
            }
            if (!isSingleSiteEnabled) {
                if ((!currentSiteFromUrl && !currentSiteFromUrlToId) && tempStorageData?.sites && tempStorageData?.sites?.length && tempStorageData?.sites?.length == 1) {
                    currentSiteFromUrlToId = tempStorageData?.sites[0];
                    currentSiteFromUrl = allSites?.find(rows => rows.value == currentSiteFromUrlToId)?.label;
                }
                if (currentSiteFromUrl && currentSiteFromUrlToId) {
                    var el = $("<input/>", { 'data-site': currentSiteFromUrlToId, 'data-site-name': currentSiteFromUrl, 'value': currentSiteFromUrlToId + '|||' + currentSiteFromUrl });
                    processSingleSiteFromMediaLocal(el);
                    setLocalStorage(true, 'isSingleSiteEnabled');
                }

                let url = new URL(location.href);
                url.searchParams.delete('site');
                window.history.pushState('', '', url);
            }
        }, 1000);
    }

    return (
        <>
            <div className='p-0 h-100'>
                <button type="button" className="reload-media-data float-right btn btn-primary shadow-none mr-2 fa fa-reload d-none" title="Get Latest Data" onClick={() => getMediaData(true)}>
                    <CachedIcon />
                </button>

                <button type="button" className="reload-media-initialization float-right btn btn-primary shadow-none mr-2 fa fa-reload d-none" onClick={() => select2InitializationMedia()}>
                    <CachedIcon />
                </button>
                <div className="min-h-53vh h-100 w-100 position-relative">
                    {loadMediaData == true && initialData?.length > 0 ?
                        <div className={'overflow-auto table-fit media-custom-height p-4 media-tab d-flex flex-column gap-2'}>
                            {initialData.map((value, index) =>
                                <div className="accordion" id={"accordion" + index} key={'accordian-' + index}>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"heading" + index}>
                                            <button className={index == 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded={index == 0 ? "true" : "false"} aria-controls={"collapse" + index}>
                                                {value?.label}
                                            </button>
                                        </h2>
                                        <div key={'collapse-' + index} id={"collapse" + index} className={index == 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + index} data-bs-parent={"#accordion" + index}>
                                            <div className="accordion-body" key={index}>
                                                <div className="row">
                                                    {value?.value?.map((valueImage, indexImage) =>
                                                        <div className="col-md-3 p-0 float-left pull-left img-container" key={'gallery-' + indexImage}>
                                                            <a href={valueImage?.image} data-lightbox={value?.label}
                                                                data-title={"Image-" + (indexImage + 1) + '-' + valueImage?.datetime}
                                                                data-alt={"Image-" + (indexImage + 1) + '-' + valueImage?.datetime}>
                                                                <img className="w-100 p-1" src={valueImage?.image}
                                                                    alt={"Image-" + (indexImage + 1) + '-' + valueImage?.datetime} />
                                                                <br />
                                                                {valueImage?.datetime}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        :
                        <>
                            <div className="middle no-media-data-container d-none">
                                <h1>There are no images for this site</h1>
                                <hr />
                            </div>

                            <div className="middle media-select-site-data-container d-none">
                                <h1>Select Site</h1>
                                <div className='w-100'>
                                    <div className="form-group d-flex flex-row align-items-center gap-2 filter-site-media">
                                        <select name='filter_site_media' className="w-100 select2-init-media-site form-control"
                                            onChange={() => processSingleSiteFromMediaLocal(event)} >
                                            <option data-site='' data-site-name='' value='' key='site-first'>Please select site</option>
                                            {allSites.map(({ value, label }, index) => <option
                                                data-site={value} data-site-name={label}
                                                value={value + '|||' + label} key={'site-' + index}>{label}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}