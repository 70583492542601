import React from "react";

export const Footer = () => {
  var isOpen = false;
  var todayDate = new Date().getFullYear();
  return (
    <>

      <div className="footer">
        <div className="">
            <div className="col-md-12 p-2">
              
              <div className="d-flex mx-auto flex-row justify-content-between gap-4 align-items-center text-center">
                <span className="w-100">
                Copyright © {todayDate} Davids Engineering, Inc.
                </span>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
