import { createSlice } from "@reduxjs/toolkit";
// import CommonService from "../../app/services/CommonService";

const initialCommonState = {
    loading: false,
};

export const CommonSlices = createSlice({
    name: "common",
    initialState: initialCommonState,
    reducers: {
        startCall: (state, action) => {
            state.error = null;
            state.loading = true;
        },

        endCall: (state, action) => {
            state.error = null;
            state.loading = false;
        },

        // catchError: (state, action) => {
        //     state.error = action.payload.message;
        //     state.loading = false;
        //     CommonService.exceptionPrompt('Error!', action.payload.message, 'error');
        // },

        interest: (state, action) => {
            state.error = null;
            state.loading = false;
        },
    }
});

export const { endCall } = CommonSlices.actions;