import React from "react";
// import {Switch} from "react-router-dom";
import BasePage from "./BasePage";

export function Routes() {
    return (
        <div>
            <BasePage/>
        </div>
    );
}
