import axios from 'axios';
import env from "react-dotenv";


function getLocalToken(token_type) {
    const tokenString = localStorage.getItem('token');
    if (tokenString && tokenString !== undefined && tokenString !== 'undefined') {
        const userToken = JSON.parse(tokenString);
        return token_type == 'access' ? userToken?.access : userToken?.refresh;
    }
    return '';
}

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Authorization': 'Bearer ' + getLocalToken('access'),
    },
    async: false,
});
