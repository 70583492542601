import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import './App.scss';
import { createTheme, ThemeProvider } from "@mui/material";
import LoginPage from "./pages/Login";
import api from "../api";
import { getUser, getLocalUser, getToken, setToken } from "./pages/common-functions.js"

const theme = createTheme({});

export default function App() {
    const token = getToken();
    const user = getLocalUser();
    if (!token) {
        if (
            window.location.pathname == '/reset-password'
            || window.location.pathname == '/login'
            || window.location.pathname.indexOf('password_reset/confirm') >= 0 
        ) {

        } else {
            window.location.href = '/login' + location.search;
        }
    } else if (token && !user) {
        getUser();
    }
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes />
            </BrowserRouter>
        </ThemeProvider>
    );
}
