import "handsontable/dist/handsontable.min.css";
import "pikaday/css/pikaday.css";
import 'handsontable/dist/handsontable.full.css';
import * as React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/scss/bootstrap.scss';
import '../style.css'
import api from "../../../api";
import { activateSingleSiteClick, bindSummaryHtml, disableSingleSite, getCurrentTimeZone, getFilteredSiteData, getLocalStorage, getMapType, getOptionsLength, getSelectedLength, handsontableInitializationForMapInfoWindow, onlyUnique, setLocalStorage, setMapType, tableSettingsParamsForMapInfoWindow } from "../common-functions";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

let initialDataTemp = [];
let sites = [];
let sitesAll = [];
let lastOpenedInfoWindow;
let map = "";
let lat = "";
let long = "";
let zoomScale = 7;
let track_map = true;
let stock_info = [];
let sites_data = [];
let stock_info_fetched = [];
let stock_locations = [];
let cluster = true;
let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
let mapTypeStorage = getMapType('mapType') ?? 'terrain';
let viewBy = 'submissions';
if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
    viewBy = 'site';
}

function generateFilterDataMapObject(rowsPerPageArgument, currentPageArgument) {
    let getFromCookie = true;
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    var returnObj = {
        startDate: $('[name="start_date"]').val(),
        endDate: $('[name="end_date"]').val(),
        'meta.instanceID': $('[name="submission_id"]').val() ? [$('[name="submission_id"]').val()] : '',
        'tz': getCurrentTimeZone()
    };

    if (rowsPerPageArgument > 0) {
        returnObj['chunk'] = rowsPerPageArgument;
    }
    if (currentPageArgument > 0) {
        returnObj['start'] = currentPageArgument > 1 ? ((rowsPerPageArgument * (currentPageArgument - 1))) : currentPageArgument - 1;
    }

    if (tempStorageData?.qcFlags?.length && getFromCookie) {
        returnObj['qcFlags'] = tempStorageData?.qcFlags;
    }
    else if (getOptionsLength('[name="filter_qc_flag"]') != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        returnObj['project'] = tempStorageData?.projects;
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        returnObj['formGroup'] = tempStorageData?.formGroups;
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        returnObj['forms'] = tempStorageData?.forms;
    }

    if (tempStorageData?.sites?.length && getFromCookie) {
        returnObj['sites'] = tempStorageData?.sites;
    }
    else if (getOptionsLength('[name="filter_site"]') != getSelectedLength('[name="filter_site"]') && $('[name="filter_site"]').val()?.length && !getFromCookie) {
        // if (old_sites_length != getSelectedLength('[name="filter_site"]')) {
        returnObj['sites'] = $('[name="filter_site"]').val();
    }

    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && process.env.REACT_APP_SINGLE_SITE_MODE == 1 && tempStorageData?.singleSite != 'undefined') {
        if (!returnObj['sites']?.length) {
            returnObj['sites'] = [];
        }
        var tempSiteIndex = returnObj['sites']?.findIndex(rows => rows == tempStorageData?.singleSite);
        if (tempSiteIndex == -1 || !tempSiteIndex || returnObj['sites']?.length <= 0) {
            returnObj['sites'].push(tempStorageData?.singleSite?.toString());
        }
        if (returnObj['sites']?.length == 1 && sitesAll?.length) {
            sitesAll?.map((rows, index) => {
                var tempSiteIndex1 = returnObj['sites']?.findIndex(rows => rows?.value == tempStorageData?.singleSite);
                if (tempSiteIndex1 == -1 || !tempSiteIndex1) {
                    // returnObj['sites'].push(rows?.value)
                }
            })
            // returnObj['chunk'] = 1000000;
        }
    } else if (viewBy == 'site') {
        // returnObj['chunk'] = 1000000;
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        returnObj['clients'] = tempStorageData?.clients;
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        returnObj['monitors'] = tempStorageData?.monitors;
    }

    if (tempStorageData?.params?.length && getFromCookie) {
        returnObj['params'] = tempStorageData?.params;
    }
    else if ($('[name="filter_param"]').val()?.length) {
        returnObj['params'] = $('[name="filter_param"]').val();
    }

    returnObj['startDate'] = $('[name="log_start_date"]').val();
    returnObj['endDate'] = $('[name="log_end_date"]').val();
    if ($('[name="keyword"]').val()) {
        returnObj['keyword'] = $('[name="keyword"]').val();
    }
    if (getOptionsLength('[name="filter_editor"]') != getSelectedLength('[name="filter_editor"]')) {
        returnObj['editor'] = $('[name="filter_editor"]').val();
    }
    if (getOptionsLength('[name="filter_image_fields"]') != getSelectedLength('[name="filter_image_fields"]')) {
        returnObj['image_fields'] = $('[name="filter_image_fields"]').val();
    }

    if (returnObj['sites']?.length > 0) {
        returnObj['sites'] = returnObj['sites'].filter(onlyUnique);
    }
    return returnObj;
}

export const loadMapData = (response, sitesFromData, rowsPerPage, currentPage, resetViewBy = false) => {
    if (resetViewBy) {
        viewBy = 'submissions';
        $('.view-by-filter:last').find('label:last').click()
    }
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        viewBy = 'site';
    }
    sites_data = sitesFromData;
    if (jQuery('#map:visible').length) {
        var locations = eval(response['latLong']);
        var infowindowData = eval(response['message']);

        if (infowindowData?.length > 0) {
            infowindowData = infowindowData.map((obj, index) => {
                Object.keys(obj).forEach(key => {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                    locations[index].site = obj?.site?.toString() || 'unknown-site-' + index;
                    locations[index].instanceID = obj['meta.instanceID'];
                });
                return obj;
            });
        }
        lat = response['lat'];
        long = response['long'];
        track_map = true;
        stock_info = infowindowData;
        stock_locations = locations;
        zoomScale = 7;

        var tempSitesObj = getLocalStorage('singleSiteData');
        if (tempSitesObj?.lat && tempSitesObj?.lat != '' && tempSitesObj?.long) {
            lat = tempSitesObj.lat;
            long = tempSitesObj?.long;
            if (lat) {
                zoomScale = 17;
            }
        }
        var ckb = jQuery("#cluster").is(':checked');
        if (viewBy == 'site') {
            var rowsPerPageArgument = rowsPerPage;
            var currentPageArgument = currentPage;
            let postParam = generateFilterDataMapObject(rowsPerPageArgument, currentPageArgument);
            let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
            api.post(`map-by-site/`, postParam, {}).then(sitesDataTemp => {
                if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
                    delete postParam['sites'];
                }
                sites = [];
                api.post(`map-by-site/`, postParam, {
                }).then(sitesData => {
                    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
                        sites = sitesData?.data?.data;
                        sitesDataTemp?.data?.data?.message?.map((rows, indexTemp) => {
                            if (sites.message?.findIndex(rowsTemp => rowsTemp?.id == rows?.id) == -1) {
                                sites.latLong.push(sitesDataTemp?.data?.data?.latLong[indexTemp])
                                sites.message.push(rows)
                                sites.total = sites.total + 1;
                            }
                        })
                    } else {
                        sites = sitesDataTemp?.data?.data;
                    }
                    sites = getFilteredSiteData(sites);
                    initializeMap(locations, infowindowData, zoomScale, lat, long, ckb, viewBy);
                });
            });
        } else {
            initializeMap(locations, infowindowData, zoomScale, lat, long, ckb, viewBy);
        }
    }
}

const initializeMap = (stock_locations, stock_info, zoomScale = 7, lat = 36.7783, long = 119.4179, ckb, markerType) => {
    if (ckb) {
        show_in_map(stock_locations, stock_info, "Cluster", lat, long, zoomScale, markerType);
    } else {
        show_in_map(stock_locations, stock_info, "Plain", lat, long, zoomScale, markerType);
    }
}
// Draw map and add markers to the map
const show_in_map = (locations, INFO, state, lat = 36.7783, long = 119.4179, zoomScale = 7, mapMarkerType = 'submissions') => {
    if (track_map) {
        let locationsTemp = locations;
        let INFOTemp = INFO;
        let locationsTempFiltered = locationsTemp;
        if (mapMarkerType == 'site') {
            locationsTempFiltered = [];
            sites?.message?.map((obj, indexDataSite) => {
                locationsTempFiltered.push({ locations: [sites?.latLong[indexDataSite]], ...obj, lat: sites?.latLong[indexDataSite]?.lat, lng: sites?.latLong[indexDataSite]?.lng, site: obj?.name })
            })
        }
        mapTypeStorage = getMapType('mapType') ?? 'terrain';
        if (lat != '' && long != '') {
            map = new google.maps.Map(document.getElementById('map'), {
                zoom: zoomScale,
                scaleControl: true,
                center: { lat: lat, lng: long },
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                }
            });
            map.addListener("maptypeid_changed", () => {
                setMapType(map.getMapTypeId(), 'mapType')
            });

            map.setMapTypeId(mapTypeStorage);
            initializeMarkers(locationsTempFiltered, state);
        }
    }
}

const closeLastOpenedInfoWindow = () => {
    if (lastOpenedInfoWindow) {
        lastOpenedInfoWindow.close();
    }
}


const initializeMarkers = (locationsTempFiltered, state) => {
    let locationsTempFilteredFinal = [];
    locationsTempFiltered?.map(function (location, ind) {
        if (locationsTempFilteredFinal.findIndex(row => row.lat == location.lat) == -1) {
            locationsTempFilteredFinal.push(location);
        }
    });

    var circle = {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: 'red',
        fillOpacity: .8,
        scale: 10.5,
        strokeColor: 'white',
        strokeWeight: 5
    };

    var whiteCircle = {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: 'white',
        fillOpacity: .8,
        scale: 10.5,
        strokeColor: 'black',
        strokeWeight: 5
    };
    $('.single-site-click').click();
    var markers;
    markers = locationsTempFilteredFinal?.map(function (location, ind) {
        var title = '';
        if (location['site'] && location['site'].indexOf('nknown-site') >= 0) {
            title += 'Site: ' + location['site'];
        }
        var maar = new google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            label: '',
            map: map,
            title: title,
            id: location['site'],
            icon: location['site'] && location['site'].indexOf('nknown-site') >= 0 ? whiteCircle : circle
        });
        let infowindow = null;
        maar.addListener('click', function () {
            closeLastOpenedInfoWindow();
            var detailsData = location;
            var tempObj = {};
            let details = {};
            if (viewBy == 'submissions') {
                for (var j in detailsData) {
                    if (detailsData.hasOwnProperty(j)) {
                        if (j.indexOf("nstanceID") > 0) {
                            var ree = detailsData[j].toString().replace(/\\/g, "");
                            tempObj = {
                                'meta.instanceID': [ree]
                            };
                        }
                    }
                }
            }

            jQuery('.loader-container').removeClass('d-none');
            if (viewBy == 'submissions' && stock_info_fetched.find(rows => rows['meta.instanceID'] == tempObj['meta.instanceID'][0])) {
                details = stock_info_fetched.find(rows => rows['meta.instanceID'] == tempObj['meta.instanceID'][0]);
                openMarkerInfo(viewBy, infowindow, details, map, maar);
            } else {
                if (viewBy !== 'submissions') {
                    var current_site = sites_data?.find(rows => rows?.label == location['site']);
                    current_site = !current_site ? (sites?.message?.find(rows => rows?.name == location['site'])) : current_site;
                    if (!current_site?.value) {
                        current_site.value = current_site?.id?.toString();
                        current_site.label = current_site?.name;
                    }
                    api.post(`site/summary/`, { site: current_site?.value ?? current_site?.id?.toString(), tz: getCurrentTimeZone() }, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(resSiteSummary => {
                        details.site = current_site?.label ?? current_site?.name;
                        setLocalStorage(resSiteSummary?.data?.data, 'singleSiteData')
                        $('.single-site-click').click();
                        openMarkerInfo(viewBy, infowindow, details, map, maar, resSiteSummary?.data?.data);
                    });
                } else if (viewBy == 'submissions') {
                    api.post(`map/info/`, tempObj, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {
                        details = res.data.data.message[0];
                        stock_info_fetched.push(details);
                        openMarkerInfo(viewBy, infowindow, details, map, maar);
                    });
                }
            }
        });
        return maar;
    });

    if (state == "Cluster") {
        new MarkerClusterer({ map, markers }, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            maxZoom: 20,
        });
        // new MarkerClusterer(map, markers, {
        //     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        //     maxZoom: 20,
        // });
    }

    setTimeout(() => {
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        let singleSiteData = getLocalStorage('singleSiteData') ?? {};
        if (markers?.length == 1 && viewBy !== 'submissions' && tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            let infowindow = null;
            api.post(`site/summary/`, { site: tempStorageData?.singleSite?.toString(), tz: getCurrentTimeZone() }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(resSiteSummary => {
                var details = {};
                details.site = tempStorageData?.singleSiteName;
                details.label = tempStorageData?.singleSiteName;
                details.value = tempStorageData?.singleSite;
                setLocalStorage(resSiteSummary?.data?.data, 'singleSiteData')
                $('.single-site-click').click();
                openMarkerInfo(viewBy, infowindow, details, map, markers[0], resSiteSummary?.data?.data);
            });
        } else if (viewBy !== 'submissions' && tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            let infowindow = null;
            var markerTemp = markers.find(rowSite => rowSite.id == tempStorageData?.singleSiteName);
            var details = {};
            details.site = tempStorageData?.singleSiteName;
            details.label = tempStorageData?.singleSiteName;
            details.value = tempStorageData?.singleSite;
            setLocalStorage(singleSiteData, 'singleSiteData')
            $('.single-site-click').click();
            openMarkerInfo(viewBy, infowindow, details, map, markerTemp, singleSiteData);
        }
        jQuery('.loader-container').addClass('d-none');
    }, 10);
}

const openMarkerInfo = (viewBy, infowindow, details, map, maar, resSiteSummary = []) => {
    var ans = prepareMapInfo(details, viewBy, resSiteSummary);
    var anss = ans;
    if (infowindow) {
        infowindow.close();
    }
    infowindow = new google.maps.InfoWindow({
        content: anss,
        maxWidth: 2000,
        minWidth: 500
    });
    infowindow.addListener('domready', function () {
        var iwOuter = document.querySelector('.gm-style-iw');
        var iwBackground = iwOuter.previousSibling ? iwOuter.previousSibling : null;
        iwOuter.style.width = '1000px';
        if (iwBackground != null && iwBackground.children[1]) {
            iwBackground.children[1].style.left = '1000px';
        }
        activateSingleSiteClick();
        setTimeout(() => {
            if ($('.activate-single-site:first').is(':visible')) {
                $('.activate-single-site:first').click();
            }
        }, 100);
    });
    infowindow.open(map, maar);
    lastOpenedInfoWindow = infowindow;
    jQuery('.loader-container').addClass('d-none');
}

const prepareMapInfo = (details, viewBy, resSiteSummary = []) => {
    var ans = "";
    var tempCount = 0;
    var current_site = sites_data?.find(rows => rows?.label == details['site']);
    current_site = !current_site ? (sites?.message?.find(rows => rows?.name == details['site'])) : current_site;
    current_site = !current_site ? details : current_site;
    if (!current_site?.value) {
        current_site.value = current_site?.id?.toString();
        current_site.label = current_site?.name;
    }
    if (viewBy == 'site') {
        if (details['site']) {
            ans += '<h5>' + details['site'] + '</h5>';
        }

        ans += '<div class="tab-content py-4 px-2">';
        ans += '<div class="tab-content px-2 d-flex gap-4"  data-site="' + current_site?.value + '" data-site-name="' + current_site?.label + '">';
        ans += '<span class="activate-single-site summary" id="summary' + current_site?.value + '-tab">Summary</span>';

        if (process.env.REACT_APP_TABLE_TAB == 1) {
            ans += '<a href="javascript:;" class="activate-single-site table-tab" id="table' + current_site?.value + '-tab">Table</a>';
        }

        if (process.env.REACT_APP_COMMENT_TAB == 1) {
            ans += '<a href="javascript:;" class="activate-single-site comments" id="comments' + current_site?.value + '-tab">Tasks</a>';
        }

        if (process.env.REACT_APP_LOG_TAB == 1) {
            ans += '<a href="javascript:;" class="activate-single-site log" id="log' + current_site?.value + '-tab">Log</a>';
        }

        if (process.env.REACT_APP_MEDIA_TAB == 1) {
            ans += '<a href="javascript:;" class="activate-single-site media" id="media' + current_site?.value + '-tab">Media</a>';
        }

        if (process.env.REACT_APP_GRAPH_TAB == 1) {
            ans += '<a href="javascript:;" class="activate-single-site graph" id="graph' + current_site?.value + '-tab">Graph</a>';
        }
        ans += '</div>';

        ans += '<div class="tab-content py-4 px-2">';
        var summary = bindSummaryHtml(resSiteSummary);
        ans += '<div class="tab-pane fade show active" id="summary' + current_site?.value + '" role="tabpanel" aria-labelledby="summary' + current_site?.value + '-tab">' + summary + '</div>';
        ans += '</div>';
        ans += '</div>';

    } else {
        if (details['site']) {
            ans += '<h5><b>Site: </b>' + details['site'] + '</h5>';
        }
        ans += '<table class="table table-responsive">';
        for (var k in details) {
            if (details.hasOwnProperty(k)) {
                ans += (tempCount % 2 == 0) ? '<tr>' : '';
                ans += "<td><b>" + k + "</b></td>";
                if (k.indexOf("image") > 0) {
                    var ree = details[k].toString().replace(/\\/g, "");
                    ree = "<td><a href='javascript:;' onclick='window.open(" + ree + ", 'newwindow',  'width=700,height=500');  return false;'>" + ree + "</a>";
                    ans += ree + "</td>";
                }
                else {
                    ans += '<td>' + details[k] + "</td>";
                }
                tempCount++;
                ans += (tempCount % 2 == 0) ? '</tr>' : '';
            }
        }
        ans += (tempCount % 2 != 0) ? '</tr>' : '';
        ans += '</table>';
    }

    return ans;
}

export const DataMap = ({ initialFilters, valueDataState, setValueDataState, initialData, rowsPerPage, currentPage, totalRows, setTotalRowsCustom, subTotalRows, setPageCustom, setCurrentPage, allSites }) => {
    sitesAll = allSites;
    const [siteTotalRows, setSiteTotalRows] = React.useState(totalRows);
    React.useEffect(() => {
        setInterval(() => {
            if ($('.gm-style-iw-d').is(':visible')) {
                $('.form-group.position-absolute').addClass('d-none')
                $('.gmnoprint').addClass('d-none')
                $('.gm-control-active.gm-fullscreen-control').addClass('d-none')
            } else {
                $('.form-group.position-absolute').removeClass('d-none')
                $('.gmnoprint').removeClass('d-none')
                $('.gm-control-active.gm-fullscreen-control').removeClass('d-none')
            }
        }, 1000);
        tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
            handleChangeViewBy({ target: { value: 'site' } }, true);
        }
    }, []);

    const [viewByValue, setViewByValue] = React.useState(viewBy);
    const [clusterState, setClusterState] = React.useState(true);

    initialDataTemp = initialData;
    if (valueDataState && valueDataState == 1) {
        setTimeout(() => {
        }, 100);
    }

    // On change of cluster
    const handleChangeCluster = (event, newValue) => {
        var tempSitesObj = getLocalStorage('singleSiteData');
        if (tempSitesObj?.lat && tempSitesObj?.long) {
            lat = tempSitesObj.lat;
            long = tempSitesObj?.long;
            if (lat) {
                zoomScale = 17;
            }
        } else {
            let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
            if (tempStorageData?.singleSiteLat && tempStorageData?.singleSiteLong) {
                lat = tempStorageData.singleSiteLat;
                long = tempStorageData?.singleSiteLong;
                if (lat) {
                    zoomScale = 17;
                }
            }
        }
        cluster = event?.target?.checked;
        setClusterState(cluster);
        if (lat != '' && long != '' && zoomScale > 4) {
            initializeMap(stock_locations, stock_info, zoomScale, lat, long, cluster, viewBy);
        }
    }

    const handleChangeViewBy = (event, apiCall = true) => {
        var tempSitesObj = getLocalStorage('singleSiteData');
        if (tempSitesObj?.lat && tempSitesObj?.lat != '' && tempSitesObj?.long) {
            lat = tempSitesObj.lat;
            long = tempSitesObj.long;
            if (lat) {
                zoomScale = 17;
            }
        }
        viewBy = event.target.value;
        setViewByValue(viewBy)

        if (viewBy == 'submissions') {
            disableSingleSite();
        }
        var ckb = jQuery("#cluster").is(':checked');
        if (!sites?.length && apiCall) {
            var rowsPerPageArgument = rowsPerPage;
            var currentPageArgument = currentPage;
            let postParam = generateFilterDataMapObject(rowsPerPageArgument, currentPageArgument);
            let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
            api.post(`map-by-site/`, postParam, {}).then(sitesDataTemp => {
                if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
                    delete postParam['sites'];
                }
                sites = [];
                api.post(`map-by-site/`, postParam, {
                }).then(sitesData => {
                    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
                        sites = sitesData?.data?.data;
                        sitesDataTemp?.data?.data?.message?.map((rows, indexTemp) => {
                            if (sites.message?.findIndex(rowsTemp => rowsTemp?.id == rows?.id) == -1) {
                                sites.latLong.push(sitesDataTemp?.data?.data?.latLong[indexTemp])
                                sites.message.push(rows)
                                sites.total = sites.total + 1;
                            }
                        })
                    } else {
                        sites = sitesDataTemp?.data?.data;
                    }
                    sites = getFilteredSiteData(sites);
                    initializeMap(stock_locations, stock_info, zoomScale, lat, long, ckb, event.target.value);
                    setSiteTotalRows(sites.total);
                    updateRowsCount(viewBy, sites.total);
                })
            })
        } else {
            initializeMap(stock_locations, stock_info, zoomScale, lat, long, ckb, event.target.value);
            updateRowsCount(viewBy);
        }

    }

    const getSitesCount = () => {
        const postParams = generateFilterDataMapObject();
        if (sites?.total) {
            return sites?.total;
        }
        else if (postParams['sites'] && postParams['sites']?.length) {
            return postParams['sites']?.length;
        }
        return 1;
    }
    const updateRowsCount = (viewBy, sitesCount) => {
        var rowsPerPageArgument = rowsPerPage;
        if (viewBy == 'site') {
            var tempCount = sitesCount ?? siteTotalRows;
            setTotalRowsCustom(tempCount);
            setPageCustom(Math.ceil(tempCount / rowsPerPageArgument));

            // $('.pagination-container-inner').hide();
        } else {
            setTotalRowsCustom(subTotalRows);
            setPageCustom(Math.ceil(subTotalRows / rowsPerPageArgument));

            // $('.pagination-container-inner').show();
        }
    }

    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1 && viewBy != 'site') {
        viewBy = 'site';
        setViewByValue(viewBy)
    }


    return (
        <>
            <div className='p-0 h-100'>
                <button type="button" className="d-none single-site-click" onClick={() => updateRowsCount(viewBy, getSitesCount())}>
                </button>
                <div className="min-h-53vh h-100 w-100 position-relative">
                    <div className="form-group position-absolute left-0 z-1 px-3 py-3 map-overlay-filter">
                        <span className='bg-white px-3 mx-2 py-2 rounded cursor-pointer'>
                            <label><input type="checkbox" name="cluster" value="cluster" id="cluster" checked={clusterState} onChange={handleChangeCluster} /> Use Map Clustering.</label>
                        </span>
                        {process.env.REACT_APP_SINGLE_SITE_MODE == 1 ?
                            <span className='bg-white px-3 py-2 rounded cursor-pointer view-by-filter'>
                                <span className="mx-2 font-weight-bold">View By :</span>
                                <label><input type="radio" className="ml-2" name="view-by" value="site" id="" checked={viewByValue == "site"} onChange={handleChangeViewBy} /> Site</label>
                                <label><input type="radio" className="ml-3" name="view-by" value="submissions" id="" checked={viewByValue == "submissions"} onChange={handleChangeViewBy} /> Submissions</label>
                            </span>
                            : <></>
                        }
                    </div>
                    <div id="map" className="map-container " style={{ height: 'calc(100vh - 210px)', width: '100%' }}></div>
                </div>
            </div>
        </>
    );
};