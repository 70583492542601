import Handsontable from "handsontable";
import { getOptionsLength, getSelectedLength, clearCache, restoreRenderer, initRestoreEvents, getLocalStorage, getCurrentTimeZone, generateFilterCountAndAvailability } from '../common-functions.js'
import "handsontable/dist/handsontable.min.css";
import 'handsontable/dist/handsontable.full.css';
import "pikaday/css/pikaday.css";
import * as React from 'react';
import API from '../../../api';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/scss/bootstrap.scss';
import '../style.css'
import { Card, CardContent, Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CachedIcon from '@mui/icons-material/Cached';
import ResetIcon from '@mui/icons-material/Restore';
import Badge from 'react-bootstrap/Badge';

jQuery.fn.modal.Constructor.prototype.enforceFocus = function () { };
jQuery.fn.modal.Constructor.prototype._enforceFocus = function () { };
jQuery.fn.modal.Constructor.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };

let uneditable = ['uuid', 'metaId', '__id', '_id', '*meta-instance-id*', 'FormID', 'form_id', 'form', 'deviceid', 'deviceId', 'ID', '__ID', 'Form', 'FormID', 'formId', 'FormId', 'Device', 'device_id', 'form_group', 'odk_form_id', 'meta.instanceID', 'client_id', 'project', 'editor', 'date', 'site', 'monitor', 'monitor_phone', 'monitor_id', 'field', 'site_id', 'column_name', 'old_value', 'new_value', 'createdAt', 'updatedAt', 'modifiedAt', 'created_at', 'updated_at', 'modified_at'];
let updateData = {};
let hot = '';
let qcFlags = [];
let projects = [];
let clients = [];
let monitors = [];
var g_longitude = 0;
var g_latitude = 0;
let columnsDataTableInitial = [];
let editableDataOptions = [];
let tableSettingsParamsForLog = {
    data: [],
    allowEmpty: true,
    fillHandle: true,
    columns: [],
    stretchH: 'all',
    colHeaders: [],
    columnSorting: true,
    sortIndicator: true,
    filters: true,
    selectionMode: 'multiple', // 'single', 'range' or 'multiple',
    width: '100%',
    height: '100vh',
    stretchH: 'last',
    minSpareRows: 0,
    minWidth: '100%',
    rowHeaders: true,
    contextMenu: ['undo', 'redo', 'copy', 'cut', 'make_read_only', 'alignment'],
    startRows: 5,
    startCols: 5,
    manualRowResize: false,
    manualColumnResize: false,
    manualRowMove: false,
    licenseKey: 'non-commercial-and-evaluation',
    renderAllRows: true,
    dropdownMenu: true,
    dropdownMenu: [
        'undo',
        'redo',
        'remove_col',
        '---------',
        'make_read_only',
        '---------',
        'alignment',
    ],
    dropdownMenu: {
        items: {
            'undo': {
                key: 'undo',
                name: 'Undo',
            },
            'redo': {
                key: 'redo',
                name: 'Redo',
            },
            'remove_col': {
                key: 'remove_col',
                name: 'Remove Column',
            },
            'make_read_only': {
                key: 'make_read_only',
                name: 'Make Readonly',
            },
            'alignment': {
                key: 'alignment',
                name: 'Alignment',
            },
            '---------': {
                key: '---------'
            },
            'selectAll': {
                key: 'selectAll',
                name: 'Select All',
                callback(key, options, event) {
                    $('.restore-log-row').each(function (event, data) {
                        if (!$(data).is(':checked')) {
                            $(data).click();
                        }
                    });
                }
            },
            'clearSelection': {
                key: 'clearSelection',
                name: 'Clear Selection',
                callback(key, options, event) {
                    $('.restore-log-row').each(function (event, data) {
                        if ($(data).is(':checked')) {
                            $(data).click();
                        }
                    });
                }
            },
        }
    },
    afterSelectionEnd: (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        // preventScrolling.value = true;
        for (var i = row; i < (row2 + 1); i++) {
            const temp = hot.getDataAtRow(i);
            if (!$('[data-id="' + temp[0] + '"]').is(':checked')) {
                $('[data-id="' + temp[0] + '"]').click();
            }
        }
    },
    afterChange: function (changes, source) {
        let columnData = columnsDataTableInitial;
        if (source === "autofill" || source === 'edit' || source === 'paste') {
            for (var j in changes) {
                if (changes.hasOwnProperty(j)) {
                    var rowNumber = changes[j][0];
                    var metaId = hot.getDataAtRowProp(rowNumber, columnData.indexOf('meta.instanceID'));
                    var metainstanceid = hot.getDataAtRowProp(rowNumber, columnData.indexOf('meta.instanceID'));
                    var oldData = changes[j][2];
                    var newData = changes[j][3];
                    var colName = columnData[changes[j][1]];
                    if (colName) {
                        if (colName == "Monitor" || colName == "MonID") {
                            if (oldData.indexOf('Unknown') >= 0) {
                                oldData = "-1";
                            }
                            else {
                                oldData = oldData.split('_')[1];
                            }
                            if (newData.indexOf('Unknown') >= 0) {
                                newData = "-1";
                            }
                            else {
                                newData = newData.split('_')[1];
                            }
                        }
                        if (colName == "qc_flag") {
                            oldData = qcFlags.find(rows => rows.label == oldData)?.value;
                            if (!oldData) {
                                oldData = "";
                            }
                            newData = qcFlags.find(rows => rows.label == newData)?.value;
                            if (!newData) {
                                newData = "";
                            }
                        }
                        var tempSitesObj = [];
                        for (var i in editableDataOptions.sites) {
                            if (editableDataOptions.sites.hasOwnProperty(i)) {
                                tempSitesObj.push({ label: editableDataOptions.sites[i], value: i });
                            }
                        }
                        if (colName == "site" && tempSitesObj.length > 0) {
                            if (oldData.indexOf('Unknown') >= 0 && oldData.indexOf('Unknown') <= 10) {
                                oldData = "-1";
                            } else {

                                oldData = tempSitesObj.find(rows => rows.label == oldData)?.value;
                                if (!oldData) {
                                    oldData = "";
                                }
                            }
                            if (newData.indexOf('Unknown') >= 0 && newData.indexOf('Unknown') <= 10) {
                                newData = "-1";
                            } else {
                                newData = tempSitesObj.find(rows => rows.label == newData)?.value;
                                if (!newData) {
                                    newData = "";
                                }
                            }
                        }
                        if (colName == "monitor" && monitors.length > 0) {
                            if (oldData.indexOf('Unknown') >= 0) {
                                oldData = "-1";
                            } else {
                                oldData = monitors.find(rows => rows.label == oldData)?.value;
                                if (!oldData) {
                                    oldData = "";
                                }
                            }
                            if (newData.indexOf('Unknown') >= 0) {
                                newData = "-1";
                            } else {
                                newData = monitors.find(rows => rows.label == newData)?.value;
                                if (!newData) {
                                    newData = "";
                                }
                            }
                        }
                        if (colName == "Site") {
                            if (oldData.indexOf('Unknown') >= 0) {
                                oldData = "-1";
                            }
                            else {
                                oldData = oldData.split('_')[0];
                            }
                            if (newData.indexOf('Unknown') >= 0) {
                                newData = "-1";
                            }
                            else {
                                newData = newData.split('_')[0];
                            }
                        }
                        if (oldData?.toString() != newData?.toString()) {
                            if (updateData.hasOwnProperty(metaId)) {
                                if (updateData[metaId].hasOwnProperty(colName)) {
                                    updateData[metaId][colName]['new'] = newData;
                                }
                                else {
                                    updateData[metaId][colName] = {};
                                    updateData[metaId][colName]['old'] = oldData;
                                    updateData[metaId][colName]['new'] = newData;
                                    updateData[metaId]['meta_instanceID'] = metainstanceid;
                                }
                            }
                            else {
                                updateData[metaId] = {};
                                updateData[metaId][colName] = {};
                                updateData[metaId][colName]['old'] = oldData;
                                updateData[metaId][colName]['new'] = newData;
                                updateData[metaId]['meta_instanceID'] = metainstanceid;
                            }
                            if (colName == "Site" && allow_siteid == true) {
                                var induz = newData.split('_')[0];
                                if (induz != "-1") {
                                    if (sites_store_lat.hasOwnProperty(induz)) {
                                        allow_long = false;
                                        hot.setDataAtCell(rowNumber, (g_latitude), sites_store_lat[induz]);
                                        hot.setDataAtCell(rowNumber, (g_longitude), sites_store_long[induz]);
                                        allow_long = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    afterRender: function (isForced) {
        initRestoreEvents();
    },
    cells: function (row, col, prop) {
        var cellProperties = {};
        if (prop === 'meta_instanceID' || prop === 'meta.instanceID') {
            cellProperties.readOnly = true;
        }
        if (prop === '_id') {
            cellProperties.readOnly = true;
        }

        return cellProperties;
    },
};


function compulsoryColumns(columns, columnsInitialParam) {
    var needed_columns = ['restore'];
    columns = columns.length > 0 && columns[0] !== '' ? columns : columnsInitialParam;
    if (columns) {
        for (var i in columns) {
            if (columns.hasOwnProperty(i)) {
                if (needed_columns.indexOf(columns[i]) < 0) {
                    needed_columns.push(columns[i]);
                }
            }
        }
    }
    return needed_columns;
}

function arrange_column_header(columnData) {
    var ANS = [];
    g_latitude = columnData.indexOf('geopoint:Latitude')
    g_longitude = columnData.indexOf('geopoint:Longitude');
    columnData = Object.assign({}, columnData);
    for (var i in columnData) {
        if (columnData.hasOwnProperty(i)) {
            var one = {};
            if (columnData[i] == "restore") {
                one['renderer'] = restoreRenderer;
                one['readOnly'] = true;
            }
            else if (columnData[i] == "client_ids") {
                one['type'] = "dropdown";
                one['source'] = clients?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "projects") {
                one['type'] = "dropdown";
                one['source'] = projects?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "monitor_id" || columnData[i] == "Monitor") {
                var monitorTemp = ['Unknown'];
                for (var indTemp in editableDataOptions.monitors) {
                    if (editableDataOptions.monitors.hasOwnProperty(indTemp)) {
                        monitorTemp.push(editableDataOptions.monitors[indTemp]);
                    }
                }
                one['type'] = "autocomplete";
                one['strict'] = false;
                one['source'] = monitorTemp;
            }
            else if (columnData[i] == "QcFlag" || columnData[i] == "QCFlag" || columnData[i] == "qc_flag") {
                one['type'] = "dropdown";
                one['source'] = qcFlags?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "site") {
                var sitesTemp = ['Unknown'];
                for (var indSite in editableDataOptions.sites) {
                    if (editableDataOptions.sites.hasOwnProperty(indSite)) {
                        sitesTemp.push(editableDataOptions.sites[indSite]);
                    }
                }
                one['type'] = "autocomplete";
                one['strict'] = false;
                one['source'] = sitesTemp;
            } else if (columnData[i] == "monitor") {
                var monitorTemp = ['Unknown'];
                for (var indMonitor1 in editableDataOptions.monitors) {
                    if (editableDataOptions.monitors.hasOwnProperty(indMonitor1)) {
                        monitorTemp.push(editableDataOptions.monitors[indMonitor1]);
                    }
                }
                one['type'] = "autocomplete";
                one['strict'] = false;
                one['source'] = monitorTemp;
                one['readOnly'] = false;
            }

            else {
                one['type'] = "text";
                one['readOnly'] = false;
            }


            if (columnData[i] != 'monitor' && (uneditable.indexOf(columnData[i]) >= 0 || uneditable.indexOf(columnData[i]?.toLowerCase()) >= 0)) {
                one['readOnly'] = true;
            }
            one['columnIndex'] = i;
            one['columnName'] = columnData[i];
            ANS.push(one);
        }
    }
    return ANS;
}

function generateFilterDataObject(rowsPerPageArgument, currentPageArgument, columnData, getFromCookie = true) {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    var returnObj = {
        startDate: $('[name="log_start_date"]').val(),
        endDate: $('[name="log_end_date"]').val(),
        'meta.instanceID': $('[name="submission_id"]').val() ? [$('[name="submission_id"]').val()] : '',
        'tz': getCurrentTimeZone(),
    };

    if (rowsPerPageArgument > 0) {
        returnObj['chunk'] = rowsPerPageArgument;
    }
    if (currentPageArgument > 0) {
        returnObj['start'] = currentPageArgument > 1 ? ((rowsPerPageArgument * (currentPageArgument - 1))) : currentPageArgument - 1;
    }

    if (tempStorageData?.qcFlags?.length && getFromCookie) {
        returnObj['qcFlags'] = tempStorageData?.qcFlags;
    }
    else if (getOptionsLength('[name="filter_qc_flag"]') != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        returnObj['project'] = tempStorageData?.projects;
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        returnObj['formGroup'] = tempStorageData?.formGroups;
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        returnObj['forms'] = tempStorageData?.forms;
    }

    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        returnObj['sites'] = [tempStorageData?.singleSite];
    }
    else if (tempStorageData?.sites?.length && getFromCookie) {
        returnObj['sites'] = tempStorageData?.sites;
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        returnObj['clients'] = tempStorageData?.clients;
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        returnObj['monitors'] = tempStorageData?.monitors;
    }

    if (getOptionsLength('[name="filter_param"]') != getSelectedLength('[name="filter_param"]') && $('[name="filter_param"]').val()?.length) {
        returnObj['params'] = $('[name="filter_param"]').val();
    }

    returnObj['startDate'] = $('[name="log_start_date"]').val();
    returnObj['endDate'] = $('[name="log_end_date"]').val();
    if ($('[name="keyword"]').val()) {
        returnObj['keyword'] = $('[name="keyword"]').val();
    }
    if (getOptionsLength('[name="filter_editor"]') != getSelectedLength('[name="filter_editor"]')) {
        returnObj['editor'] = $('[name="filter_editor"]').val();
    }
    return returnObj;
}

export const DataLog = ({ initialFilters, valueDataState, setValueDataState }) => {
    const [busy, setBusy] = React.useState([]);
    const [hasFilter, setHasFilter] = React.useState([]);
    const [filterCount, setFilterCount] = React.useState(0);
    const [initialData, setInitialData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(Math.ceil(initialData.length / rowsPerPage));
    const [totalRows, setTotalRows] = React.useState(Math.ceil(initialData.length));
    const [currentPage, setCurrentPage] = React.useState(1);

    if (valueDataState && valueDataState == 3) {
        setTimeout(() => {
            // handsontableInitializationForLog();
        }, 100);
    }

    // Handle pagination
    const handlePaginationForLog = async (event, newValue, rowsPerPageArgument, initialDataArgument) => {
        rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        setRowsPerPage(rowsPerPageArgument);
        setCurrentPage(newValue);
        if (!initialDataArgument) {
            getDataPaginatedForLogs(rowsPerPageArgument, newValue, false, false);
            return false;
        }
        initialDataArgument = initialDataArgument ? initialDataArgument : initialData;

        initialDataArgument = initialDataArgument?.map(rows => {
            var tempIndex = tableSettingsParamsForLog.colHeaders.findIndex(rows => rows == '_id');
            return [
                rows[tempIndex - 1],
                ...rows
            ]
        })

        const tempArrayPagination = [];
        initialDataArgument.map((row, indexData) => {
            var tempIndex = tableSettingsParamsForLog.colHeaders.findIndex(rows => rows == 'site');
            if (tempIndex > 0 && row[tempIndex] && row[tempIndex] == '-1.0') {
                row[tempIndex] = 'Unknown';
            }

            var tempIndex = tableSettingsParamsForLog.colHeaders.findIndex(rows => rows == 'monitor');
            if (tempIndex > 0 && row[tempIndex] && row[tempIndex] == '-1.0') {
                row[tempIndex] = 'Unknown';
            }
            tempArrayPagination.push(row);
        })
        tableSettingsParamsForLog.data = tempArrayPagination;
        setTimeout(() => {
            handsontableInitializationForLog();
        }, 100);
    };

    const onGetCommentCount = async (isInitial, resetColumns, rowsPerPageArgument) => {
        if (!isInitial) {
            jQuery('.loader-container').removeClass('d-none');
        }

        rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        var currentPageArgument = currentPageArgument ? currentPageArgument : currentPage;
        let columnData = [];
        columnData = compulsoryColumns(columnData, columnsDataTableInitial);
        let postParam = generateFilterDataObject(0, 0, columnData);

        await API.post(`log/get-count/`, postParam).then(res => {
            const resData = res?.data?.data;
            let total = res?.data?.data?.total;
            setTotalRows(total);
            setPage(Math.ceil(total / rowsPerPageArgument))
        });
    };

    // Get data from API
    const getCommentsData = async (isInitial, closeModal = true) => {
        if (isInitial) {
            jQuery('.loader-container').removeClass('d-none');
            jQuery('.no-record-logs-container').addClass('d-none');
            var rowsPerPageArgument = 100;
            var currentPageArgument = 1;
            jQuery('.MuiPagination-ul').find('li:first > .MuiButtonBase-root').click();
            getDataPaginatedForLogs(rowsPerPageArgument, currentPageArgument, false, isInitial);
        } else {
            setTimeout(() => {
                jQuery('.loader-container').removeClass('d-none');
                jQuery('.no-record-logs-container').addClass('d-none');
                var rowsPerPageArgument = 100;
                var currentPageArgument = 1;
                jQuery('.MuiPagination-ul').find('li:first > .MuiButtonBase-root').click();
                getDataPaginatedForLogs(rowsPerPageArgument, currentPageArgument, false, isInitial, closeModal);
            }, 1000);
        }
    };

    // Get data from API
    const getDataPaginatedForLogs = async (rowsPerPageArgument, currentPageArgument, isDownload, isInitial, closeModal = true) => {
        jQuery('.loader-container').removeClass('d-none');
        jQuery('.no-record-logs-container').addClass('d-none');
        var rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        var currentPageArgument = currentPageArgument ? currentPageArgument : currentPage;
        setRowsPerPage(rowsPerPageArgument);
        setCurrentPage(currentPageArgument);
        let columnData = $('[name="filter_column"]').val();
        if (columnData.length && columnData.length == 1 && columnData[0] == '') {
            columnData = [];
        }
        columnData = compulsoryColumns(columnData, columnsDataTableInitial);
        let postParam = generateFilterDataObject(rowsPerPageArgument, currentPageArgument, columnData);
        // postParam['download'] = isDownload;
        if (isDownload) {
            // postParam['downloadInit'] = currentPageArgument > 1 ? ((rowsPerPageArgument * (currentPageArgument - 1)) + 1) : currentPageArgument;
        }
        const getFilterData = generateFilterCountAndAvailability(columnData, false, true);
        setHasFilter(getFilterData.hasFilter);
        setFilterCount(getFilterData.totalFilters);
        if (!isInitial) {
            onGetCommentCount(true, true, rowsPerPageArgument).then(res => {
            });
        }
        await API.post(`log/get/`, postParam, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            if (isDownload) {
                let fileResData = res?.data?.data;
                if (fileResData.filepath) {
                    jQuery('.loader-container').addClass('d-none');
                    var path = fileResData.filepath.replace(':8080', '');
                    window.open(path, '_blank');
                }
            } else {
                let resData = res?.data?.data?.data;
                columnsDataTableInitial = res?.data?.data['data-table-columns'];
                if (resData?.length) {
                    jQuery('#handsontableForLogContainerParent').removeClass('d-none');
                    let responseData = eval(resData);
                    var columnsObj = compulsoryColumns(columnsDataTableInitial, columnsDataTableInitial);
                    var columnsDataTemp = arrange_column_header(columnsObj);
                    if (columnsDataTemp[0]) {
                        columnsDataTemp[0].columnSorting = { headerAction: false };
                    }
                    tableSettingsParamsForLog.colHeaders = columnsObj;
                    tableSettingsParamsForLog.columns = columnsDataTemp;
                    setInitialData(responseData);
                    setTimeout(() => {
                        handlePaginationForLog({}, currentPageArgument, rowsPerPageArgument, responseData);
                    }, 200);
                } else {
                    setInitialData([]);
                    jQuery('.no-record-logs-container').removeClass('d-none');
                    jQuery('#handsontableForLogContainerParent').addClass('d-none');
                    setTimeout(() => {
                        handlePaginationForLog({}, 1, rowsPerPageArgument, []);
                        setPage(Math.ceil(1 / rowsPerPageArgument))
                        setTotalRows(1);
                    }, 200);
                }
            }
        }).catch(error => {
            setInitialData([]);
            jQuery('.no-record-logs-container').removeClass('d-none');
            setTimeout(() => {
                handlePaginationForLog({}, 1, rowsPerPageArgument, []);
                setPage(Math.ceil(1 / rowsPerPageArgument))
                setTotalRows(1);
                clearCache(error);
            }, 200);
        });
    }

    // Inital load
    React.useEffect(async () => {
        jQuery('.loader-container').removeClass('d-none');
        setBusy(false);
        // getCommentsData(true).then(res1 => {
        //     setBusy(false);
        //     setTimeout(() => {
        //         onGetCommentCount(true, true, rowsPerPage).then(res => {
        //         });
        //     }, 500);
        // });
    }, []);

    // Initialize datatable
    const handsontableInitializationForLog = () => {
        let containerForTable = document.getElementById("handsontableForLogContainer");
        let hiddenColumnsPlugin = '';
        if (jQuery('.ht_master').length > 0 && hot) {
            hot.updateSettings(tableSettingsParamsForLog);
            hiddenColumnsPlugin = hot.getPlugin('hiddenColumns');
        } else {
            let tempHot = new Handsontable(containerForTable, tableSettingsParamsForLog);
            hot = tempHot;
            hiddenColumnsPlugin = hot.getPlugin('hiddenColumns');
        }
        hiddenColumnsPlugin.enablePlugin();
        // hiddenColumnsPlugin.hideColumn(1);
        hot.render();
        setTimeout(() => {
            jQuery('.loader-container').addClass('d-none');
        }, 1000);
    }

    const reset = () => {
        $('.reset-filter-button').click();
    }
    
    const openFilterModal = () => {
        $('#exampleModal').modal('show');
    }

    const handleChangePerPage = (event, newValue) => {
        setRowsPerPage(event.target.value);
        setPage(Math.ceil(totalRows / event.target.value))
        setCurrentPage(1);
        setTimeout(() => {
            handlePaginationForLog({}, 1, event.target.value);
        }, 1000);

    };

    return (
        <>
            <div className='data_header d-flex flex-md-row gap-2 justify-content-center justify-content-xxl-between pt-2 pb-3 pb-xxl-0 pt-xxl-0'>
                <div className="px-2 other-tab-container-header">
                </div>
                <div className='pagination-block d-flex flex-column flex-md-row align-items-center gap-5 justify-content-end'>
                    <div className="pagination-mobile d-flex flex-md-row align-items-center gap-5">
                        <div className="other-tab-container-header">
                            <button type="button" disabled="disabled" className="btn btn-primary shadow-none restoreData mb-2">
                                Restore
                            </button>
                        </div>
                        <Pagination
                            selected={false}
                            size={'medium'}
                            count={page}
                            defaultPage={currentPage}
                            page={currentPage}
                            shape="rounded"
                            variant="outlined"
                            color="primary"
                            showFirstButton
                            showLastButton
                            siblingCount={0}
                            boundaryCount={1}
                            onChange={handlePaginationForLog} />
                        <div className="sorting-pagination d-flex d-md-flex-row align-items-center gap-5">
                            <select className='form-control w-10' onChange={handleChangePerPage} value={rowsPerPage}>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                                <option value={1000}>1000</option>
                            </select>
                            <strong>{currentPage > 1 ? ((rowsPerPage * (currentPage - 1)) + 1) : currentPage} to {((totalRows > rowsPerPage * currentPage) ? rowsPerPage * currentPage : totalRows)} of {totalRows}</strong>
                            <Box className={'float-right'}>
                                <Card sx={{ Width: '100%' }}>
                                    <CardContent className={'d-lg-flex d-md-block d-md-flex-row justify-content-start align-items-center lg-gap-2 pl-0 pt-0 pagination-container float-right table-filter'}>
                                        <div className='d-lg-flex justify-content-between align-items-center w-100'>
                                            <div className='d-lg-flex d-md-flex-row'>
                                                <button type="button" className="reload-log-data float-right btn btn-primary shadow-none mr-2 fa fa-reload" disabled={busy} title="Get Latest Data" onClick={() => getDataPaginatedForLogs(rowsPerPage, currentPage, false, false)}>
                                                    <CachedIcon />
                                                </button>

                                                <button type="button" className="float-right btn btn-primary shadow-none mr-2" title="Filter" disabled={busy} onClick={() => openFilterModal()}>
                                                    <FilterAltIcon />
                                                </button>

                                                <button type="button" className="float-right btn btn-primary shadow-none mr-4 fa fa-reload" disabled={!hasFilter || busy} title="Reset Filter" onClick={() => reset()}>
                                                    <ResetIcon />
                                                    {
                                                        filterCount > 0 ?
                                                            <Badge bg="danger" className='filter-count-badge'>
                                                                <span>{filterCount}</span>
                                                            </Badge>
                                                            :
                                                            <></>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Box>
                        </div>
                    </div>

                </div>
            </div>

            <div className={'overflow-auto1 table-fit table-log-custom-height'} id="handsontableForLogContainerParent">
                <div className={'overflow-auto1'} id="handsontableForLogContainer"></div>
            </div>
            <div className='no-record-logs-container d-none mt-5'>
                <p className='w-100 w-full text-xl-center'>There are no logs to display.</p>
            </div>
        </>
    );
};